import React from "react";
import menuData from "../menuData"; // Import the menu data
import "./Menu.css"; // Import the CSS file

function MenuSection({ title, items }) {
  return (
    <div className="menu-section">
      <h2>{title}</h2>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <div className="menu-item">
              <div className="menu-text">
                <h3>{item.name}</h3>
                <p>{item.description}</p>
                <span>{item.price}</span>
              </div>
              {item.image && (
                <div className="menu-image">
                  <img src={item.image} alt={item.name} />
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

function Menu() {
  return (
    <div className="menu-container">
      <h1>BLISS</h1>

      {menuData.starters && (
        <MenuSection title="STARTERS" items={menuData.starters} />
      )}

      {menuData.mainCourses && (
        <MenuSection title="MAIN" items={menuData.mainCourses} />
      )}

      {menuData.setMenu && (
        <div className="menu-section">
          <h2>SET MENU FOR 2/30€</h2>
          <div className="set-menu-item">
            <ul>
              <li>
                <div className="menu-text">
                  <h3 className="set-menu-header">Starter</h3>
                  <p>{menuData.setMenu.starter.description}</p>
                </div>
              </li>
              <li>
                <h3 className="smaller-portions-header">4 Smaller Portions</h3>
                <ul>
                  {menuData.setMenu.smallerPortions.map((portion, index) => (
                    <li key={index}>
                      <div className="menu-text">
                        <h3>{portion.name}</h3>
                        <p>{portion.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <h3 className="dessert-header">1 Dessert</h3>
                <ul className="dessert-options">
                  <li>Pannacotta with Redberry cream</li>
                  <li className="or-divider">or</li>
                  <li>Chocolate cake with Whipped cream</li>
                </ul>
              </li>
            </ul>
            <div className="set-menu-image">
              <img src="/assets/SetMenu.jpg" alt="Set Menu" />
            </div>
          </div>
        </div>
      )}

      {menuData.kidsMenu && (
        <MenuSection title="KIDS MENU" items={menuData.kidsMenu} />
      )}

      {menuData.barMenu && (
        <MenuSection title="BAR MENU" items={menuData.barMenu} />
      )}

      {menuData.dessert && (
        <MenuSection title="DESSERT" items={menuData.dessert} />
      )}
    </div>
  );
}

export default Menu;
