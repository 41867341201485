const menuData = {
  starters: [
    {
      name: "1/2 Toast Skagen",
      description: "Shrimps, Mayo, Roe, Dill, Lemon",
      price: "€6.00",
      image: "/assets/white.png",
    },
    {
      name: "Charcuterie Board",
      description:
        "Chorizo, Salami, Serrano Jamon, Brie cheese, Semicirado cheese, Fig Marmalade & Crackers",
      price: "€10.50",
      image: "/assets/CharkBricka.jpg",
    },
    {
      name: "Garlic Bread",
      description: "Garlic, Butter, Parsley",
      price: "€3.00",
      image: "/assets/white.png",
    },
  ],
  mainCourses: [
    {
      name: "Meatballs with mashed potatoes",
      description: "Minced meat, Cream sauce, Pickled cucumber & Lingonberries",
      price: "€10.00",
      image: "/assets/white.png",
    },
    {
      name: "Swedish Panbiff with boiled potatoes",
      description:
        "Minced meat, Caramelized onion, Pickled cucumber & Lingonberries",
      price: "€10.00",
      image: "/assets/white.png",
    },
    {
      name: "Cajun Shrimp Pasta",
      description:
        "Shrimps, Parsley, Wine, Cream, Garlic, Paprika powder, Chiliflakes & Parmigiano",
      price: "€14.00",
      image: "/assets/CajunShrimpPasta.jpg",
    },
    {
      name: "Pasta Carbonara",
      description: "Eggs, Parmigiano, Pancetta",
      price: "€10.50",
      image: "/assets/white.png",
    },
    {
      name: "Chicken with Carrot purée & herb sauce",
      description: "Thyme, Garlic, Carrots, Basil, Parsley",
      price: "€12.50",
      image: "/assets/KycklingMorot.jpg",
    },
    {
      name: "Goat cheese salad",
      description:
        "Roasted Walnuts, Red onion, Cherry tomatoes, Radish and Pears with Honey/mustard dressing",
      price: "€9.50",
      image: "/assets/GoatCheeseSallad.jpg",
    },
    {
      name: "Hamburger",
      description:
        "Double Cheeseburger with Bacon served with Fries or Sweet potato fries",
      price: "€10.50",
      image: "/assets/white.png",
    },
  ],
  dessert: [
    {
      name: "Pannacotta with Redberry cream",
      price: "€5.00",
    },
    {
      name: "Chocolate cake",
      price: "€4.00",
    },
    {
      name: "Ice cream of today (per ball)",
      price: "€2.00",
    },
  ],
  barMenu: [
    {
      name: "Chips",
      description: "Lays Salted or Ruffles Jamon",
      price: "€1.50",
    },
    {
      name: "Dirty Fries",
      description: "Served with Cheese & Parsley",
      price: "€3.00",
    },
    {
      name: "Patatas Bravas",
      description: "Served with Salsa Bravas or Aioli",
      price: "€3.00",
    },
    {
      name: "Nachos",
      description: "With cheese, Salsa, Sour Cream & Guacamole",
      price: "€5.00",
    },
    {
      name: "Chicken Wings",
      description: "Served with BBQ sauce",
      price: "€5.00",
    },
    {
      name: "Chicken Nuggets",
      description: "Served with a Dip of your choice",
      price: "€5.00",
    },
    {
      name: "Extra Dip Sauce",
      description: "Aioli, Mayo, BBQ, Hot Sauce, Sweet Chili, Curry Mango",
      price: "€1.00",
    },
  ],
  setMenu: {
    starter: {
      name: "Charcuterie Board",
      description:
        "Chorizo, Salami, Serrano Jamon, Brie cheese, Semicirado cheese, Fig Marmalade & Crackers",
    },
    smallerPortions: [
      {
        name: "Meatballs with mashed potatoes",
        description:
          "Minced meat, Cream sauce, Pickled cucumber & Lingonberries",
        image: "/assets/SetMenu.jpg",
      },
      {
        name: "Pasta Carbonara",
        description: "Eggs, Parmigiano, Pancetta",
      },
      {
        name: "Chicken with Carrot purée & Herb sauce",
        description: "Thyme, Garlic, Carrots, Basil, Parsley",
      },
      {
        name: "Goat cheese salad",
        description:
          "Roasted Walnuts, Red onion, Cherry tomatoes, Radish and Pears with Honey/Mustard dressing",
      },
    ],
    dessert: [
      "Pannacotta with Redberry cream",
      "Chocolate cake with Whipped cream",
    ],
  },
  kidsMenu: [
    {
      name: "Meatballs with mashed potatoes",
      description: "Minced meat, Cream sauce, Pickled cucumber, Lingonberries",
      price: "€7.00",
    },
    {
      name: "Pasta Carbonara",
      description: "Eggs, Parmigiano, Pancetta",
      price: "€6.00",
    },
    {
      name: "Hamburger",
      description: "Minced meat, Lettuce, Ketchup (w/wo cheese)",
      price: "€7.50",
    },
  ],
};

export default menuData;
