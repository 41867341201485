import React from "react";
import "./AboutUs.css";
import mainImage from "../assets/background.jpg"; // Update the path if necessary

function AboutUs() {
  return (
    <main className="main-container">
      <div className="image-container">
        <img src={mainImage} alt="Main" className="main-image" />
        <div className="overlay">
          <h1>Swedish restaurant with homemade food</h1>
          <div className="opening-hours">
            <h2>Opening hours:</h2>
            <p>Monday-Saturday: 14-22 (late)</p>
            <p>Sunday: Closed</p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AboutUs;
