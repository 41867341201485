import React from "react";
import { Link } from "react-router-dom";
import "./Header.css"; // Import the CSS file

function Header() {
  return (
    <header>
      <div className="logo-container">
        <img
          src={require("../assets/BlissLogo.jpg")}
          alt="Logo"
          className="logo"
        />
      </div>
      <nav>
        <ul className="nav-links">
          <li>
            <Link to="/">About Us</Link>
          </li>
          <li>
            <Link to="/menu">Menu</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
