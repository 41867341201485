import React from "react";
import "./Footer.css"; // Import the CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-info">
          <a
            href="https://www.facebook.com/profile.php?id=61558397969859"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} className="footer-icon" /> Bliss
            Bar & Restaurante
          </a>
          <a
            href="https://www.instagram.com/blissrestaurante.es"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} className="footer-icon" />{" "}
            @blissrestaurante.es
          </a>
          <a href="mailto:cincosuecos@gmail.com">
            <FontAwesomeIcon icon={faEnvelope} className="footer-icon" />{" "}
            cincosuecos@gmail.com
          </a>
          <p>
            <FontAwesomeIcon icon={faPhone} className="footer-icon" /> +34 865
            940 859
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
